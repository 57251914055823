<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start" @click="back">
      <i class="el-icon-arrow-left"></i>
      {{ $t('jiao-yi-mi-ma') }}
    </div>
    <div class="edit-form">
      <el-form :model="form" ref="form" :rules="formRule">
        <el-form-item
          :label="$t('jiu-mi-ma')"
          prop="OldPassword"
          required
          v-if="isSet"
        >
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="form.OldPassword"
            :placeholder="$t('qing-shu-ru-jiu-mi-ma-1')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword = !showPassword"
              >
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('xin-mi-ma')" prop="Password" required>
          <el-input
            :type="showPassword2 ? 'text' : 'password'"
            v-model="form.Password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma-1')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword2 = !showPassword2"
              >
                <img :src="eyeUrl" alt="" v-if="!showPassword2" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('que-ren-mi-ma')"
          prop="PasswordConfirm"
          required
        >
          <el-input
            :type="showPassword3 ? 'text' : 'password'"
            v-model="form.PasswordConfirm"
            :placeholder="$t('qing-que-ren-mi-ma-1')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword3 = !showPassword3"
              >
                <img :src="eyeUrl" alt="" v-if="!showPassword3" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button class="submit-btn" @click="submit">{{
            $t('que-ding')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  editSafePassword,
  setSafePassword,
  checkSafePassword
} from '@/api/user'
export default {
  components: {},
  data() {
    return {
      form: {
        OldPassword: '',
        Password: '',
        PasswordConfirm: ''
      },
      isSet: false,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      formRule: {
        OldPassword: [
          { message: this.$t('qing-shu-ru-jiu-mi-ma-0'), required: true }
        ],
        Password: [
          { message: this.$t('qing-shu-ru-xin-mi-ma-0'), required: true }
        ],
        PasswordConfirm: [
          {
            required: true,
            validator: this.checkPassword
          }
        ]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      checkSafePassword().then((res) => {
        this.isSet = res.data
      })
    },
    checkPassword(rule, value, callback) {
      if (!value) {
        return callback(this.$t('qing-que-ren-mi-ma-0'))
      } else if (this.form.Password != this.form.PasswordConfirm) {
        callback(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
      } else {
        callback()
      }
    },
    currentChange(page) {},
    back() {
      this.$router.go(-1)
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          if (this.isSet) {
            editSafePassword({
              OldPassword: this.form.OldPassword,
              Password: this.form.Password,
              PasswordConfirm: this.form.PasswordConfirm
            }).then((res) => {
              if (res.code == 0) {
                this.$message.success(this.$t('xiu-gai-cheng-gong'))
                this.$refs.form.resetFields()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            setSafePassword({
              SafePassword: this.form.Password,
              SafePasswordConfirm: this.form.PasswordConfirm
            }).then((res) => {
              if (res.code == 0) {
                this.$message.success(this.$t('she-zhi-cheng-gong'))
                this.$refs.form.resetFields()
                this.isSet = true
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
        }
      })
    }
  }
}
</script>